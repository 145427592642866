.banner {
  position: relative;
  align-items: center;
  color: var(--white-color);
  overflow: hidden;
  padding-bottom: 0;
  @include adaptiveValue(margin-bottom, 100, 64, 1920, 360, 1);
  padding-top: 64px;
  padding-bottom: 172px;
  min-height: 360px;

  @media (min-width: 576px) {
    padding-bottom: 64px;
  }

  @media (min-width: 768px) {
    min-height: 500px;
  }

  @media (min-width: 992px) {
    height: calc(100vh - 64px);
    //min-height: 705px;
    //max-height: calc(100vh - 63px);
  }

  &.banner-with-gradient {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }
  }

  &.banner-with-gradient-left {
    &::before {
      @include adaptiveValue(width, 746, 400, 1920, 360, 1);
      height: 100%;
      background: linear-gradient(90deg, rgba(12,41,57,0.75) 0%, rgba(12,41,57,0) 100%);
    }
  }

  &.banner-with-gradient-top {
    &::before {
      height: 100%;
      @include adaptiveValue(width, 746, 400, 1920, 360, 1);
      background: linear-gradient(90deg, rgba(12,41,57,0.75) 0%, rgba(12,41,57,0) 100%);
      @media (min-width: 600px) {
        width: 100%;
        height: 746px;
        @include adaptiveValue(height, 747, 400, 1920, 360, 1);
        background: linear-gradient(180deg, rgba(12,41,57,0.75) 0%, rgba(12,41,57,0) 100%);
      }
    }
  }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: var(--primary-color);
  }

  &__inner {
    min-height: inherit;
    height: 100%;
    justify-content: start;
    row-gap: var(--secondary-gap);
    z-index: 2;

    @media (min-width: 576px) {
      padding-top: 0;
      justify-content: center;
      row-gap: 32px;
    }
  }

  &__title {
    font-size: 48px;
    z-index: 2;
    line-height: 1;
    max-width: 638px;
    span {
      font-size: 32px;
    }
  }

  p {
    font-size: 24px;
    z-index: 2;
  }

  &__btn {
    z-index: 2;
    @media (min-width: 576px) {
      max-width: 300px;
    }
  }
}
