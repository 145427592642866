.features {
  &__inner {
    grid-template-columns: repeat(auto-fill, minmax(min(248px, 100%), 1fr));
    gap: var(--primary-gap);
  }

  &__item {
    padding: var(--secondary-padding);
    background-color: var(--primary-lighter-color);
    row-gap: 8px;
    grid-auto-rows: min-content;
    min-height: 232px;
  }

  &__item-notice {
    background-color: var(--primary-color);
    font-size: 24px;
    color: var(--white-color);
    min-height: unset;

    @media (min-width: 768px) {
      min-height: 232px;
    }
  }

  &__item-notice p {
    @media (min-width: 576px) {
      max-width: 100%;
    }
  }

  &__text {
    margin-bottom: var(--secondary-gap);
  }
}
