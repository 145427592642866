/* stylelint-disable */

@mixin for-desktop {
  @media (min-width: (1025px)) {
    @content;
  }
}

@mixin big-desktop {
  @media (max-width: (1440px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (1024px)) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: (768px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (576px)) {
    @content;
  }
}
