.purpose {
  &__item {
    min-height: 192px;
    row-gap: 40px;
    grid-template-rows: max-content 1fr;
  }

  &__info {
    row-gap: 5px;
  }

  &__img {
    align-self: end;
  }

  &__inner {
    gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 312px), 1fr));
    margin-bottom: 16px;

    @media (min-width: 768px) {
      gap: var(--primary-gap);
    }
  }

  &__title {
  }

  &__notice {
    @media (min-width: 576px) {
      justify-items: center;
    }
    row-gap: 5px;
    padding: var(--secondary-padding);
  }

  span {
    color: var(--grey-color);
  }
}
