html {
  box-sizing: border-box;
}


*,
*::before,
*::after {
  box-sizing: inherit;
}

button {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  padding: 0;
  margin: 0;
  min-width: 320px;
  height: 100%;
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  font-family: var(--font-family-primary), Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: var(--dark-color);
  background-color: #FDFDFE;

  padding-top: 64px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
  color: inherit;
}

.site-container {
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto; // change to auto 1fr auto if header is not absolute
}

.is-hidden {
  display: none !important;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  max-width: var(--container-width);
  padding: var(--container-paddings);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.ibg {
  position: relative;
}

.ibg img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.global-wrapper {
  position: relative;
}


.locked {
  overflow: hidden;
}


.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: var(--transition-duration) color ease, var(--transition-duration) background-color ease, var(--transition-duration) border ease;
}

.btn-primary {
  padding: var(--secondary-padding);
  width: 100%;
  border-radius: var(--primary-border-radius);
  text-transform: uppercase;
  font-weight: 600;

  @media (min-width: 768px) {
    width: unset;
    padding: 22px 16px;
    min-width: 310px;
    max-width: 420px;
  }
}

.btn-main {
  background-color: var(--primary-color);
  color: var(--white-color);
  @include hover {
    background-color: var(--primary-dark-color);
  }
}

.btn-outline {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);

  @include hover {
    color: var(--primary-dark-color);
    border: 1px solid var(--primary-dark-color);
  }
}

.section-title {
  font-weight: 600;
  font-size: var(--h2-font-size);
  @include adaptiveValue(margin-bottom, 32, 24, 1920, 360, 1);
}

.section-subtitle {
  font-size: var(--h3-font-size);
  font-weight: 600;
  color: var(--primary-color);
}

.primary-item {
  @include adaptiveValue(padding-top, 24, 16, 1920, 360, 1);
  @include adaptiveValue(padding-bottom, 24, 16, 1920, 360, 1);
  @include adaptiveValue(padding-right, 24, 16, 1920, 360, 1);
  @include adaptiveValue(padding-left, 24, 16, 1920, 360, 1);
  background-color: var(--primary-lighter-color);
}

section {
  @include adaptiveValue(padding-bottom, 100, 64, 1920, 360, 1);
}

.nav-link {
  padding-bottom: 3px;
  display: inline-block;
  font-weight: 600;
}

.primary-hover {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    height: 2px;
    bottom: 0;
    will-change: width;
    transition: var(--transition-duration) width ease;
  }

  @include hover {
    &::before {
      width: 100%;
    }
  }

  &-light {
    &::before {
      background-color: var(--white-color);
    }
  }

  &-dark {
    &::before {
      background-color: var(--dark-color);
    }
  }

  &-primary-color {
    &::before {
      background-color: var(--primary-color);
    }
  }
}

.btn-light {
  background-color: var(--white-color);
}

.socials {
  column-gap: 8px;
  flex-wrap: wrap;
}
