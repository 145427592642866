@use "sass:math";

$containerWidth: 1290;
$maxWidth: 1920;
$minWidth: 320;

@mixin adaptiveValue($property, $startSize, $minSize, $widthFrom: $containerWidth, $widthTo: $minWidth, $keepSize: 0) {
	@if ($startSize==0) {
		$startSize: 0.000001;
	}
	@if ($minSize==0) {
		$minSize: 0.000001;
	}

	// Для calc();
	$addSize: math.div($startSize - $minSize, 16);

	@if ($widthFrom == $containerWidth and $maxWidthContainer == 0) {
		$widthFrom: $maxWidth;
	}

	// Брейк-поинты в EM
	$widthFromMedia: em($widthFrom);
	$widthToMedia: em($widthTo);

	// Формула плавающего значения
	// Источник: https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
	$slope: math.div(($startSize - $minSize), ($widthFrom - $widthTo));
	$yIntersection: -$widthTo * $slope + $minSize;
	@if ($yIntersection==0) {
		$yIntersection: 0.000001;
	}
	$flyValue: #{rem($yIntersection)}" + " #{$slope * 100}vw;

	// Получение значения свойства
	$propertyValue: #{"clamp(" rem($minSize) "," $flyValue "," rem($startSize) ")"};
	// Если отрицательные значения
	@if ($minSize > $startSize) {
		$propertyValue: #{"clamp(" rem($startSize) "," $flyValue "," rem($minSize) ")"};
	}

	// Если поддерживается clamp();
	@supports (#{$property}: $propertyValue) {
		#{$property}: $propertyValue;
	}
	// Если не поддерживается clamp();
	@supports not (#{$property}: $propertyValue) {
		#{$property}: calc(#{rem($minSize)} + #{$addSize} * (100vw - #{rem($widthTo)}) / #{math.div($widthFrom, 16) - math.div($widthTo, 16)});
	}

	// Устанавливаем значения по умолчанию
	@if $widthFrom != $containerWidth and $widthFrom != $maxWidth and $keepSize != 1 and $keepSize != 2 {
		@media (min-width: $widthFromMedia) {
			#{$property}: inherit;
		}
	}
	@if $widthTo != $minWidth and $keepSize != 1 and $keepSize != 3 {
		@media (max-width: $widthToMedia) {
			#{$property}: inherit;
		}
	}
}
