.advice {
  &__inner {
    @media (min-width: 768px) {
      grid-template-columns: repeat(12, 1fr);
    }
  }

  &__title {
    position: relative;
    margin-bottom: 0;
    padding-left: 40px;

    &::before {
      content: "";
      width: 35px;
      height: 35px;
      position: absolute;
      left: 0;
      top: 2px;
      background-repeat: no-repeat;
      background-image: url('../img/svg/advice.svg');
    }
  }


  &__item {
    row-gap: var(--secondary-gap);

    @media (min-width: 768px) {
      grid-column: span 12;
    }

    @media (min-width: 992px) {
      grid-column: span 6;
    }
  }


  &__item-head {
    row-gap: var(--secondary-gap);
  }

  &__item-content {
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
      gap: var(--secondary-gap);
    }
  }


  &__list {
    @media (min-width: 768px) {
      flex: 0 1 255px;
    }
  }

  &__list-item {
    position: relative;
    padding-left: 13px;
    &::before {
      content: "";
      width: 8px;
      height: 8px;
      background-color:var(--dark-color);
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 7px;
    }
  }
}
