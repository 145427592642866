.mechanics {
  &__inner {
    gap: var(--primary-gap);
    grid-template-columns: 1fr;
    @media (min-width: 992px) {
      grid-template-columns: repeat(12,1fr);
    }
  }

  &__info {
    grid-column: unset;
    grid-auto-rows: min-content;
    background-color: var(--primary-lighter-color);

    @media (min-width: 992px) {
      grid-column: 1/5;
    }

    row-gap: var(--secondary-gap);
  }

  &__scheme {
    background-color: var(--primary-lighter-color);
    grid-column: unset;
    justify-items: center;
    padding: 10px;

    @media (min-width: 992px) {
      grid-column: 5/13;

      @include adaptiveValue(padding-top, 32, 24, 1920, 992, 1);
      @include adaptiveValue(padding-bottom, 32, 24, 1920, 992, 1);
      @include adaptiveValue(padding-right, 72, 36, 1920, 992, 1);
      @include adaptiveValue(padding-left, 72, 36, 1920, 992, 1);
    }

  }

  &__img {
    padding-bottom: 24.7%;
    width: 100%;

    img {
      object-fit: contain;
    }
  }
}
