.introduction {
&__inner {
  grid-template-columns: 1fr;
  column-gap: var(--primary-gap);
  row-gap: var(--secondary-gap);
  @media (min-width: 992px) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: minmax(33px, auto) minmax(72px, auto) min-content;
    row-gap: unset;
  }
}

&__img {
  align-self: start;
  grid-row: 3/4;
  grid-column: unset;
  padding-bottom: 35%;

  @media (min-width: 992px) {
    grid-column: 5/13;
  }

  @media (min-width: 992px) {
    grid-row: span 3;
  }

  @media (min-width: 1200px) {
    grid-column: 6/13;
  }
}

&__text {
  grid-column: unset;

  max-width: 500px;
  @media (min-width: 992px) {
    grid-column: span 4;
    grid-row: 2/3;
    margin-bottom: 24px;
  }
}

&__btn {
  grid-column: unset;
  grid-row: 4/5;
  width: 100%;
  max-width: unset;
  @media (min-width: 992px) {
    grid-column: span 3;
    grid-row: 3/4;
    align-self: start;
  }
}

&__title {
  grid-column: unset;
  padding-top: 10px;
  margin-bottom: 0;
  @media (min-width: 992px) {
    grid-column: span 4;
    @include adaptiveValue(margin-bottom, 32, 24, 1920, 360, 1);
  }
}
}
