.form {
  row-gap: 24px;

  &__label {
    color: var(--grey-color);
    row-gap: 8px;
  }

  &__input {
    width: 100%;
    padding: 12px;
  }

  &__inputs {
    row-gap: var(--secondary-gap);
    column-gap: var(--primary-gap);
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__label-message {

    @media (min-width: 768px) {
      grid-column: span 2;
    }
  }

  &__textarea {
    min-height: 124px;
    resize: none;
  }

  &__notice {
    color: var(--grey-color);
    font-size: 12px;
    text-align: center;

    @media (min-width: 992px) {
      text-align: start;
    }
  }

  &__notice-link {
    text-decoration: underline;
  }



  &__footer {
    grid-template-columns: 1fr;
    row-gap: var(--small-gap);
    @media (min-width: 1200px) {
      grid-template-columns: auto 1fr;
      column-gap: var(--small-gap);
    }

    button {
      max-width: 100%;
      order: -1;
      @media (min-width: 1200px) {
        order: 1;
      }
    }
  }


}
