.grid {
  display: grid;
}

.grid-col-12 {
  grid-template-columns: repeat(12, 1fr);
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: end;
}

.items-start {
  align-items: start;
}

.direction-column {
  flex-direction: column;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.centered {
  text-align: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-grid-start {
  justify-items: start;
}

.justify-flex-start {
  justify-content: flex-start;
}

.justify-grid-center {
  justify-items: center;
}

.rounded {
  border-radius: var(--primary-border-radius);
}

.primary-border {
  border: 1px solid var(--primary-extra-light-color);
}

.rounded-left {
  border-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.rounded-right {
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.font-primary {
  color: var(--primary-color);
}

.font-dark {
  color: var(--dark-color);
}

.bg-primary-lighter {
  background-color: var(--primary-lighter-color);
}


.justify-self-center {
  justify-self: center;
}

.justify-self-end {
  justify-self: end;
}

