.guide {

  &__inner {
    grid-template-columns: 1fr;
    gap: var(--primary-gap);

    @media (min-width: 992px) {
      grid-template-columns: repeat(12, 1fr);
    }
  }

  &__time {
    row-gap: var(--secondary-gap);
    grid-column: unset;
    @media (min-width: 992px) {
      grid-column: 1/4;
    }
  }

  &__time-items {
    row-gap: var(--secondary-gap);
  }

  &__time-item {
    border: 2px solid var(--primary-color);
    padding: var(--primary-padding) 10px;
    grid-template-columns: 1fr;
    row-gap: var(--extra-small-gap);
  }

  &__documents {
    grid-column: unset;
    row-gap: var(--secondary-gap);
    @media (min-width: 992px) {
      grid-column: 4/13;
    }
  }

  &__documents-items {
    grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
    gap: var(--primary-gap);
    grid-template-rows: auto 1fr;
  }

  &__documents-item {
    min-height: 247px;
    background-color: var(--light-white-color);
    padding: var(--primary-padding);
    position: relative;
    row-gap: var(--secondary-gap);

    &::after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 50px 0 0 50px;
      border-color: #FDFDFE #FDFDFE #FDFDFE #CFDDF5;
      position: absolute;
      right: 0;
      top: 0;
      border-bottom-left-radius: var(--primary-border-radius);
    }
  }

  &__documents-subtitle {
    max-width: 206px;
  }

  &__img {
    align-self: end;
  }

}
