.functionality {

  &__inner {
    padding-left: max(calc((100% - 1290px) / 2), 25px);
    padding-right: 20px;

    @media (min-width: 768px) {
      padding-right: 0;
    }
  }

  &__interaction {
    position: absolute;
    width: 100%;
    max-width: 1260px;
    top: 50%;

    .splide__arrow {
      width: 40px;
      height: 40px;
      border-radius: 0;
      background: none;

      &--prev {
        left: 0;
      }

      &--next {
        right: 0;
      }
    }

    .splide__pagination {
      bottom: 13px;
      padding: 0;
    }

    .functionality__bullet {
      width: 10px;
      height: 10px;
      border: 1px solid var(--primary-extra-light-color);
      background-color: var(--light-white-color);
      &.is-active {
        transform: none;
        background-color: var(--primary-extra-light-color);
      }
    }

    .splide__arrow {
      background-color: rgba(0,0,0,.5);
      background-position: 50%;
      background-repeat: no-repeat;
      cursor: pointer;
      height: 60px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity .3s;
      width: 25px;
      background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9JyNmZmYnIHZpZXdCb3g9JzAgMCAyNCAyNCc+PHBhdGggZD0nbTUgMyAzLjA1Ny0zTDIwIDEyIDguMDU3IDI0IDUgMjFsOS05eicvPjwvc3ZnPg==);

      &--prev {
        transform: scaleX(-1) translateY(-50%);
      }

      &:not([disabled]) {
        opacity: 1;
      }

      @media (min-width: 768px) {
        width: 30px;
        height: 80px;
      }
    }




  }

  .placehold {
    width: 100%;
    height: 511px;
    background-color: grey;
  }

  &__img {
    width: 100%;
    padding-bottom: 55%;

    img {
      object-fit: contain;
    }
  }
}
