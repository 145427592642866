.steps {

  &__inner {
    grid-template-columns: 1fr;
    column-gap: 20px;
    align-items: start;

    @media screen and (min-width: 992px) {
      grid-template-columns: 212px 1fr;
    }
  }

  &__btns {
    row-gap: var(--secondary-gap);
    counter-reset: buttons-counter;
    display: none;

    @media (min-width: 992px) {
      display: grid;
    }
  }

  &__btn {
    padding: var(--small-padding);
    font-weight: 600;
    color: var(--grey-color);
    grid-template-columns: max-content 1fr;
    justify-items: start;
    column-gap: 5px;
    background-color: var(--primary-lighter-color);
    &.tabs-btn-active {
      color: var(--primary-color);
      border: 1px solid var(--primary-extra-light-color);
    }

  }

  &-tab-btn {
    border-radius: 100px;
    border: 1px solid var(--light-white-color);
    min-width: 212px;

    &::before {
      content: counter(buttons-counter) ".";
      counter-increment: buttons-counter;
    }
  }

  &__content {
    grid-auto-rows: min-content;
    row-gap: 24px;
    display: none;
    &.tabs-content-active {
      display: none;

      @media (min-width: 992px) {
        display: grid;
      }
    }
  }

  &__content p {
    max-width: 445px;
  }

  .placeholder {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    background-color: grey;

    img, iframe {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
  }

  &__accordion {
    display: grid;
    counter-reset: accordion-counter;
    row-gap: var(--secondary-gap);

    @media (min-width: 992px) {
      display: none;
    }
  }

  &__accordion-btn {
    padding: 0;
    position: relative;
    width: 100%;
    padding-right: 30px;
    &::before {
      content: counter(accordion-counter) ".";
      counter-increment: accordion-counter;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      width: 20px;
      height: 20px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../img/svg/arrow-down.svg");
      transition: var(--transition-duration) transform ease;
    }

  }

  &__accordion-item {
    background-color: var(--primary-lighter-color);
    padding: 0px 8px;

    &.active {
      .steps__accordion-btn {
        color: var(--primary-color);
      }


      .steps__accordion-btn::after {
        transform: rotate(180deg);
      }
    }
  }

  &__accordion-content {
    padding-bottom: 16px;
    row-gap: var(--secondary-gap);
  }

  &__accordion-body{
    overflow: hidden;
    max-height: 0;
    will-change: max-height;
    transition: var(--transition-duration) max-height ease;
  }

  &__accordion-head {
    padding: var(--secondary-padding) 0px;
  }

  .placeholder {
    @media (max-width: 576px) {
      height: 195px;
    }
  }
}
