:root {
  //fonts variables
  --font-family-primary: "Poppins";
  --h1-font-size: 48px;
  --h2-font-size: 32px;
  --h3-font-size: 24px;
  --h4-font-size: 20px;
  --body-font-size: 15px;
  --tag-font-size: 13px;
  --caption-font-size: 12px;
  --body-line-height: 1.5;


  //colors variables

  --primary-color: #216185;
  --primary-dark-color: #194964;
  --primary-light-color: #A0BAEB;
  --primary-lighter-color: #F8F9FC;
  --primary-extra-light-color: #CFDDF5;
  --dark-color: #2A2825;
  --white-color: #fff;
  --darker-white-color: #FDFDFE;
  --danger-color: #DC3444;
  --grey-color: #9F9F9F;
  --light-white-color: #F3F5F9;
  --select-active: #f1efef;


  //spacing variables

  --primary-gap: 30px;
  --secondary-gap: 16px;
  --small-gap: 10px;
  --extra-small-gap: 5px;

  --big-padding: 32px;
  --primary-padding: 24px;
  --secondary-padding: 16px;

  --small-padding: 8px;

  //style variables

  --primary-border-radius: 8px;
  --container-width: 1310px;
  --container-paddings: 0 25px;

  --transition-duration: 0.3s;

  //z indexes

  --lowest-elements: 1;
  --highest-elements: 5;

}
