
.select {
  position: relative;
  min-height: 30px;
  min-width: 60px;
  &.select-open {
    .select__list {
      visibility: visible;
      opacity: 1;

    }

    .select__item:not(.selected) {
        display: grid;
    }

    .select__item.selected a::after {
      transform: rotate(180deg) translateY(-5px);
    }
  }
  &__list {
    width: 60px;
    position: absolute;

    border-bottom-right-radius: var(--primary-border-radius);
    border-bottom-left-radius: var(--primary-border-radius);

    background-color: var(--white-color);
    overflow: auto;
  }

  &__item {
    width: 100%;
    transition: var(--transition-duration) background-color ease;
    display: none;
    text-align: start;


    @include hover {
      background-color: var(--select-active);
    }

    a {
        padding: 5px 10px 5px 10px;
    }

    &.selected {
    display: grid;
    font-weight: 600;
    position: relative;
    order: -1;
    border-top-left-radius: var(--primary-border-radius);
    border-top-right-radius: var(--primary-border-radius);
        a {
            padding: 5px 15px 5px 10px;
            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 7px;
                width: 15px;
                height: 12px;
                background-repeat: no-repeat;
                background-image: url('../img/svg/arrow-down-dark.svg');
                transition: var(--transition-duration) transform ease;
            }
        }
    }

    &:focus {
      background-color: var(--select-active);
    }
  }
}
