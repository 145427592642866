.comission {
  @include adaptiveValue(padding-top, 64, 40, 1920, 360, 1);
  @include adaptiveValue(margin-bottom, 100, 64, 1920, 360, 1);
  position: relative;
  color: var(--white-color);
  padding-bottom: 200px;

  @media (min-width: 768px) {
    padding: 64px 0;
  }

  &__inner {
    grid-template-columns: repeat(12, 1fr);
    @include adaptiveValue(row-gap, 48, 40, 1920, 360, 1);
  }

  &__info {
    grid-column: span 12;

    @media (min-width: 768px) {
      grid-column: 1/9;
    }
  }

  &__info-inner {
    column-gap: var(--primary-gap);
    row-gap: var(--secondary-gap);
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  &__info h3 {
    font-weight: 600;
  }

  &__item {
    row-gap: var(--extra-small-gap);
    grid-auto-rows: min-content;

    @media (min-width: 768px) {
      flex: 0 1 410px;
    }
  }

  &__item p {
    max-width: 362px;
  }

  &__qa {
    grid-column: span 12;

    @media (min-width: 992px) {
      grid-column: 1/9;
    }

    grid-row: 2/3;
  }

  &__qa-inner {
    row-gap: 5px;
  }

  &__list {
      li {
          margin-bottom: var(--extra-small-gap);
      }

    @media (min-width: 768px) {
        columns: 2;
    }
  }

  &__list li {
    position: relative;
    padding-left: 13px;
    max-width: 410px;
    &::before {
      content: "";
      left: 0;
      top: 0;
      background-color: var(--white-color);
      border-radius: 50%;
      width: 5px;
      height: 5px;
      position: absolute;
      top: 9px;
    }
  }

  &__bg {
    object-position: 0 87%;

    @media (min-width: 768px) {
      object-position: unset;
    }
  }

}
