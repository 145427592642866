.access {

  &__inner {
    row-gap: 25px;
  }

  &__pointers {
    display: none;
    grid-template-columns:  repeat(3, 1fr);
    justify-items: center;
    position: relative;
    counter-reset: access-counter;

    @media (min-width: 992px) {
      display: grid;
    }

    &::before {
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
      width: 65%;
      height: 2px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      background-color: var(--primary-extra-light-color);
    }
  }
  &__items {
    grid-template-columns: 1fr;
    column-gap: var(--primary-gap);
    row-gap: 8px;
    counter-reset: access-counter;

    @media (min-width: 992px) {
      row-gap: var(--secondary-gap);
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__counter {
    &::before {
      content: counter(access-counter);
      counter-increment: access-counter;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 32px;
      background-color: var(--primary-extra-light-color);
      padding: 5px;
      color: var(--primary-color);
    }
  }

  &__item {
    justify-items: center;
    row-gap: 8px;

    &:not(:last-of-type) {
      &::after {
        content: "";
        width: 32px;
        height: 32px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        background-image: url("../img/svg/down-pointer.svg");
      }
    }

    @media (min-width: 992px) {
      &::after,
      &::before {
        display: none;
      }
    }
  }



  &__content {
    padding: var(--big-padding) var(--secondary-padding);
    row-gap: 8px;
    width: 100%;
    grid-auto-rows: min-content;
  }

  &__item p {
    max-width: 385px;
  }
}
