.contacts {
  color: black;
  padding-top: 34px;

  @include adaptiveValue(padding-top, 34, 30, 1440, 360, 1);

  &__title {
  }

  &__inner {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 0;
    row-gap: var(--primary-gap);

    @media (min-width: 992px) {
      column-gap: var(--primary-gap);
    }
  }

  &__info {
    grid-column: span 12;
    column-gap: var(--secondary-gap);
    row-gap: var(--secondary-gap);
    align-items: start;
    grid-template-columns: 1fr;
    justify-items: start;

    @media (min-width: 1200px) {
      align-items: center;
    }

    @media (min-width: 992px) {
      grid-template-columns: 2fr 1fr;
      grid-column: span 6;
    }
  }

  &__details {
    row-gap: var(--secondary-gap);
    grid-template-columns: repeat(2, 1fr);
  }

  &__link {
    position: relative;
    padding-left: 22px;
    grid-column: span 2;


    @media (min-width: 1200px) {
      grid-column: span 1;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 3px;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
    }
  }

  &__link-phone::after {
    background-image: url('../img/svg/phone-black.svg');
  }

  &__link-email::after {
    background-image: url('../img/svg/letter.svg');
  }

  &__address {
    grid-column: span 2;
    @media (min-width: 1200px) {
      max-width: 400px;
    }
  }

  &__form {
      grid-column: span 12;
      row-gap: var(--primary-gap);

      form {
          display: grid;
      }

    @media (min-width: 992px) {
      grid-column: span 6;
    }
  }

  &__map {
    position: relative;
    width: 100%;
    padding-bottom: 100.5%;

    #map {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
        }
    }

    @media (min-width: 992px) {
      grid-column: span 2;
    }

    @media (min-width: 576px) {
      padding-bottom: 45.5%;
    }
  }

  &__socials {
    @media (min-width: 992px) {
      justify-self: end;
    }
  }

  &__social {

    rect {
      transition: var(--transition-duration)  fill ease;
    }

    @include hover {
      rect {
        fill: var(--primary-color);
      }
    }
  }

  &__subtitle {
    color: black;
  }

  &__btn {
      position: relative;
      padding: 25px;
      color: var(--light-white-color);
      overflow: hidden;

      button {
          position: absolute;
          inset: 0;
          color: inherit;
          text-transform: uppercase;
      }

      @media (min-width: 992px) {
          padding: 32px;
      }
  }



}
