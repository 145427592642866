.header {
  top: 0;
  position: fixed;
  left: 0;
  width: 100%;
  height: 64px;
  background-color: var(--white-color);
  z-index: var(--highest-elements);
  padding: var(--small-padding) 0;
  border-bottom: 1px solid var(--primary-extra-light-color);

  @media (min-width: 992px) {
    padding: 7px 0;
  }

  &__inner {
    gap: 15px;
  }

  &__info {
    flex-direction: column;
    gap: var(--primary-gap);

    @media (min-width: 992px) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

  }

  &__info-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--white-color);
    top: 0;
    left: 0;
    z-index: 2;
    padding: 80px 25px 25px 25px;
    row-gap: var(--secondary-gap);
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    transition: var(--transition-duration) opacity ease, var(--transition-duration) visibility ease;


    @media (min-width: 992px) {
      position: static;
      padding: 0;
      background-color: transparent;
      display: block;
      visibility: visible;
      opacity: 1;
    }

    &.menu--active {
      opacity: 1;
      visibility: visible;
    }

    &::before {
      content: "";
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 48px;
      background-color: var(--white-color);
      z-index: 2;

      @media (min-width: 992px) {
        display: none;
      }
    }
  }

  &__language {
    z-index: 3;
    position: relative;
  }

  &__logo {
    display: inline-flex;
    align-items: center;
    z-index: 3;
  }

  &__btn {
    border-radius: 117px;
    padding: 5px;
    padding: 2px 10px;

  }

  &__btn-login {
    min-width: 100px;
    border: 2px solid var(--primary-light-color);
    color: var(--primary-light-color);
    @include hover {
      border: 2px solid var(--primary-dark-color);
    }
  }

  &__btn-register {
    min-width: 117px;
  }

  &__btns {
    display: none;
    @media (min-width: 992px) {
      gap: 16px;
    }
    @media (min-width: 992px) {
      display: inline-flex;
      gap: 10px;
    }
  }

  &__btn-mob {
    @media (min-width: 768px) {
      max-width: 100%;
      flex: 1 1 50%;
    }
  }

  &__btns-mobile {
    flex-direction: column;
    row-gap: var(--secondary-gap);

    @media (min-width: 992px) {
      display: none;
    }

    @media (min-width: 768px) {
      column-gap: 30px;
      flex-direction: row;
    }
  }
}

.burger {
  @include burger;
  z-index: 3;

  @media (min-width: 992px) {
    display: none;
  }
}

.menu {
  &__list {
    column-gap: var(--primary-gap);
    row-gap: var(--secondary-gap);
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: 992px) {
      flex-direction: row;
      column-gap: var(--small-gap);
      max-width: 500px;
      row-gap: 5px;
    }

    @media (min-width: 992px) {
      column-gap: var(--secondary-gap);
    }
  }

  &__item {
    word-break: break-word;

    @media (min-width: 992px) {
      max-width: 150px;
    }
  }

  &__link {
    &::before {
      background-color: var(--dark-color);
    }

    &.active::before {
      width: 100%;
    }
  }
}

