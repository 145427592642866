.partners {
  &__inner {
    max-width: 1070px;
    margin: 0 auto;
    grid-template-columns: repeat(12, 1fr);
  }

  &__title {
    grid-column: span 12;
    @media (min-width: 992px) {
      grid-column: 2/12;
    }
  }

  &__item {
    grid-column: span 12;
    display: none;
    grid-template-rows: min-content 1fr;
    border: 1px solid var(--primary-lighter-color);
    border-top: none;
    border-bottom-left-radius: var(--primary-border-radius);
    border-bottom-right-radius: var(--primary-border-radius);

    @media (min-width: 768px) {
      grid-column: span 5;
      display: block;
    }

    &.tabs-content-active {
      display: grid;
    }
  }

  &__img-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100px;
    aspect-ratio: 190 / 80;
    border-radius: var(--primary-border-radius);
    font-size: 0.8em;

    &:empty {
      border: 1px solid var(--primary-extra-light-color);
      background: var(--darker-white-color);
      color: var(--primary-extra-light-color);

      &:after {
        content: 'no logo';
        position: absolute;
        inset: 50% auto auto 50%;
        transform: translate(-50%, -50%);
      }
    }

    @media (min-width: 992px) {
      max-width: 190px;
      font-size: 1em;
    }
  }

  &__content {
    background-color: var(--white-color);
    row-gap: var(--secondary-gap);
    padding: 16px 5px;
    grid-auto-rows: min-content;
    border-bottom-left-radius: var(--primary-border-radius);
    border-bottom-right-radius: var(--primary-border-radius);
    @media (min-width: 768px) {
      padding: 16px;
    }
  }

  &__content-item {
    width: 100%;
    column-gap: var(--secondary-gap);
  }

  &__item-operators {
    border-right: none;
    .partners__head {
      border-top-left-radius: var(--primary-border-radius);
    }

    @media (min-width: 992px) {
      grid-column: 2/7;
    }

    @media (min-width: 768px) {
      grid-column: 1/7;
    }
  }

  &__item-brokers {

    .partners__head {
      border-top-right-radius: var(--primary-border-radius);
      border-left: none;
    }

    @media (min-width: 992px) {
      grid-column: 7/12;
    }

    @media (min-width: 768px) {
      grid-column: 7/13;
    }

  }

  &__item h3 {
    font-weight: 600;
    color: var(--dark-color);
  }

  &__head {
    padding: 14px;
    text-align: center;
    background-color: var(--primary-lighter-color);
  }

  &__btns {
    display: flex;
    grid-column: span 12;
    @media (min-width: 768px) {
      display: none;
    }
  }

  &__head-desktop {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  &__btn {
    grid-column: span 12;
    margin-top: 24px;
    @media (min-width: 768px) {
      grid-column: 2/12;
    }
  }

  &-tab-btn {
    font-size: 16px;
    font-weight: 600;
    border-top-left-radius: var(--primary-border-radius);
    border-top-right-radius: var(--primary-border-radius);
    flex: 1 1 50%;
    color: var(--grey-color);
    padding: 10px 5px;

    &.tabs-btn-active {
      background-color: var(--white-color);
      color: var(--primary-color);
    }
  }
}
